'use client';

import {Box} from '@mui/material';

import useGlobalData from '../hooks/useGlobalData';
import usePageMounted from '../hooks/usePageMounted';

function WebLayoutWrapper({children}: {children: React.ReactNode}) {
  useGlobalData();

  const isMounted = usePageMounted();
  if (!isMounted) return null;

  return (
    <Box sx={{backgroundColor: 'additionalBackground'}}>
      <main>{children}</main>
    </Box>
  );
}

export default WebLayoutWrapper;
