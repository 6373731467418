import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
  SearchRideKeys,
  getDestinationsList,
} from '@/api/repositories/searchRideRepository';
import { AreaEl, GeometryListEl } from '@/api/repositories/types/tomtomTypes';

/**
 * A hook which fetches global data from the server once per web app load.
 * Every other call to this hook will return the cached data.
 */
function useGlobalData() {
  const destinationListContext = useQuery({
    queryKey: [SearchRideKeys.DESTINATION_LIST],
    queryFn: () => getDestinationsList(''),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  // generate geometry List for tomtom geometrySearch
  // and extended geometry list with destinationId
  const { geometryList, allAreas } = useMemo(() => {
    if (!destinationListContext.data)
      return { geometryList: [], geometryListExtended: [] };

    const geometryList: GeometryListEl[] = [];
    const allAreas: AreaEl[] = [];

    destinationListContext.data?.forEach((destination) => {
      if (destination.area.length > 2) {
        const geometry = {
          type: 'POLYGON',
          vertices: destination.area.map((item) => `${item.lat},${item.lng}`),
        };
        const area = {
          area: destination.area,
          destinationId: destination.id,
        };

        geometryList.push(geometry);
        allAreas.push(area);
      }
    });
    return { geometryList, allAreas };
  }, [destinationListContext.data]);

  const airports = useMemo(() => {
    return destinationListContext.data
      ?.filter((destination) => destination.isAirport)
      .map((destination) => {
        return {
          area: destination.area,
          destinationId: destination.id,
          name: destination.name,
          areaName: destination.areaName,
        };
      });
  }, [destinationListContext.data]);

  return {
    destinationList: destinationListContext.data,
    geometryList,
    allAreas,
    airports,
  };
}

export default useGlobalData;
