import axios, { AxiosError, AxiosResponse } from 'axios';
import { getSession, signOut } from 'next-auth/react';

export const BASE_URL = process.env.NEXT_PUBLIC_API_URL + '/api';

const httpClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

httpClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      const session = await getSession();
      if (session) {
        // redirect user to main page, in case he enters some page that he shouldn't be on without being logged in
        await signOut({ redirect: true, callbackUrl: '/' });
      }
    } else {
      console.error(`Error occured during request: ${error.message}`);
    }
    return Promise.reject(error);
  }
);

export { httpClient };
