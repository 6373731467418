import {useEffect, useState} from 'react';

const usePageMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }
  return isMounted;
};

export default usePageMounted;
